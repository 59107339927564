<template>
  <b-overlay
      variant="white"
      spinner-variant="primary"
      blur="0"
      opacity=".75"
      rounded="sm"
      :show="isLoading"
  >
    <validation-observer
        ref="maintenanceRecoverySaveFormRef"
        #default="{invalid}"
    >
      <b-modal
          id="maintenanceRecoverySaveModal"
          centered
          no-close-on-backdrop
          size="lg"
          :title="isCreated ? `Thêm ${resourceName.fullName}` : `Cập nhật ${resourceName.fullName}`"
          @show="onShow"
          @hide="onHide"
      >
        <b-form>
          <b-row>
            <b-col cols="12">
              <b-form-group
                  label="Tiêu đề"
                  label-for="name"
              >
                <validation-provider
                    #default="{ errors }"
                    name="Tiêu đề"
                    vid="name"
                    rules="required"
                >
                  <b-form-input
                      id="name"
                      v-model="targetData.name"
                      name="name"
                      :state="getElementState(errors)"
                  />
                  <b-form-invalid-feedback :state="getElementState(errors)">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-group
                  label="Chi tiết"
                  label-for="description"
              >
                <validation-provider
                    #default="{ errors }"
                    name="Chi tiết"
                    vid="description"
                >
                  <b-form-textarea
                      id="description"
                      v-model="targetData.description"
                      name="description"
                      :state="getElementState(errors)"
                  />
                  <b-form-invalid-feedback :state="getElementState(errors)">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
        <template #modal-footer>
          <div class="w-100 d-flex justify-content-end">
            <b-button
                v-show="isCreated"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-1"
                :disabled="invalid"
                @click="onSave"
            >
              <span class="text-right">
                <feather-icon icon="CheckIcon" /> Lưu và tiếp tục
              </span>
            </b-button>

            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-1"
                :disabled="invalid"
                @click="onSave('hide')"
            >
              <span class="text-right">
                <feather-icon icon="CheckIcon" /> Lưu lại
              </span>
            </b-button>

            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-secondary"
                @click="$bvModal.hide('maintenanceRecoverySaveModal')"
            >
              <span class="text-right">
                <feather-icon icon="XIcon" /> Hủy
              </span>
            </b-button>
          </div>
        </template>
      </b-modal>
    </validation-observer>
  </b-overlay>
</template>

<script>
import {
  BForm, BFormGroup, BFormInput, BModal, BOverlay, BFormInvalidFeedback, BButton,
  BRow,
  BCol,
  BFormTextarea,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@core/utils/validations/validations'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import * as events from 'events'

export const Attr = Object.freeze({
  store: 'maintenanceRecovery',
})
export default {
  name: 'MaintenanceRecoverySave',
  components: {
    BFormGroup,
    BFormInput,
    BModal,
    BForm,
    BOverlay,
    BFormInvalidFeedback,
    BButton,
    BRow,
    BCol,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    mainData: {
      type: Object,
      default: undefined,
    },
    extData: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      isLoading: false,
      targetData: {
        id: 0,
        groupId: this.extData.groupId,
        type: this.extData.type,
        notificationId: null,
        name: '',
        description: '',
        status: 0,
      },
      required,
    }
  },
  computed: {
    events() {
      return events
    },
    ...mapGetters({
      statuses: `${Attr.store}/statuses`,
      resourceName: `${Attr.store}/resourceName`,
    }),
    statusOptions() {
      return this.statuses
    },
    isCreated() {
      return !this.mainData
    },
  },
  methods: {
    ...mapActions({
      createData: `${Attr.store}/createData`,
      updateData: `${Attr.store}/updateData`,
    }),
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    async onShow() {
      this.isLoading = true
      if (this.mainData) {
        this.targetData = this.mainData
      }
      this.targetData.groupId = this.extData.groupId
      this.isLoading = false
    },
    onHide() {
      this.$refs
          .maintenanceRecoverySaveFormRef
          .reset()
      this.targetData = {
        id: 0,
        groupId: this.extData.groupId,
        type: this.extData.type,
        notificationId: null,
        name: '',
        description: '',
        status: 0,
      }
    },
    async onSave(type = null) {
      const valid = this.$refs
          .maintenanceRecoverySaveFormRef
          .validate()
      if (valid) {
        this.isLoading = true
        try {
          const { createdAt, createdBy, ...request } = this.targetData
          const response = this.isCreated
              ? await this.createData(request)
              : await this.updateData(request)
          if (response) {
            const {
              isSuccessful,
              message,
            } = response
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              if (type === 'hide') {
                this.$bvModal.hide('maintenanceRecoverySaveModal')
              }
              this.$emit('succeed')
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select';
</style>
