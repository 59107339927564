<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>
        Tăng trưởng dữ liệu lưu trữ
      </b-card-title>
<!--      <b-dropdown-->
<!--        dropright-->
<!--        variant="link"-->
<!--        toggle-class="p-0"-->
<!--        no-caret-->
<!--      >-->
<!--        <template #button-content>-->
<!--          <feather-icon-->
<!--            class="text-body"-->
<!--            icon="FilterIcon"-->
<!--          />-->
<!--        </template>-->
<!--        <b-dropdown-item-->
<!--          v-for="(item, index) in itemOptions"-->
<!--          :key="index"-->
<!--          @click="selectItem(item)"-->
<!--        >-->
<!--          {{ item.label }}-->
<!--        </b-dropdown-item>-->
<!--      </b-dropdown>-->
    </b-card-header>
    <b-card-body>
      <vue-apex-charts v-if="dataChartOption"
        ref="chart"
        type="line"
        :options="dataChartOption"
        :series="dataChartSeries"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BCardHeader, BCardTitle, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import { DATA_GROWTH_CHART } from '@/const/status'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export const Attr = Object.freeze({
  store: 'maintenanceBackup',
})
export default {
  components: {
    BDropdownItem,
    BDropdown,
    VueApexCharts,
    BCardHeader,
    BCard,
    BCardBody,
    BCardTitle,
  },
  data() {
    return {
      isLoading: false,
      itemOptions: DATA_GROWTH_CHART,
      seriesData: [],
      chartOptionsData: [],
      filter: {
        input: null,
      },
      dataChartOption: {},
      dataChartSeries: [],
      lineChartSimple: {
        series: [
          {
            data: [],
          },
        ],
        chartOptions: {
          chart: {
            zoom: {
              enabled: false,
            },
            toolbar: {
              show: false,
            },
          },
          markers: {
            strokeWidth: 7,
            strokeOpacity: 1,
            strokeColors: [$themeColors.light],
            colors: [$themeColors.warning],
          },
          colors: [$themeColors.warning],
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: 'straight',
          },
          grid: {
            xaxis: {
              lines: {
                show: true,
              },
            },
          },
          tooltip: {
            shared: false,
          },
          xaxis: {
            categories: [],
          },
          yaxis: {
            // opposite: isRtl,
          },
        },
      },
    }
  },
  computed: {
    ...mapGetters({
      dataGrowths: `${Attr.store}/dataGrowths`,
    }),
  },
  async created() {
    this.filter.input = 1
    await this.getDataFromStore()
  },
  methods: {
    ...mapActions({
      getDataGrowth: `${Attr.store}/getDataGrowth`,
    }),
    async getDataFromStore() {
      this.isLoading = true
      try {
        await this.getDataGrowth(this.filter)
        if (this.dataGrowths.length > 0) {
          this.seriesData = []
          this.chartOptionsData = []
          this.lineChartSimple.series[0].data = []
          this.lineChartSimple.chartOptions.xaxis.categories = []
          this.dataChartOption = null
          this.dataChartSeries = []
          this.dataGrowths.forEach(item => {
            this.seriesData.push(item.size)
            this.lineChartSimple.series[0].data = (this.seriesData)
            this.chartOptionsData.push(item.dateCreated)
            this.lineChartSimple.chartOptions.xaxis.categories = (this.chartOptionsData)
          })
          this.dataChartOption = this.lineChartSimple.chartOptions
          this.dataChartSeries = this.lineChartSimple.series
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async selectItem(item) {
      this.filter.input = item?.value
      await this.getDataFromStore()
    },
  },
}
</script>
