<template>
  <div class="row">
    <b-container fluid>
      <b-card no-body>
        <b-overlay
          :show="isLoading"
          rounded="sm"
        >
          <div class="m-2">
            <b-row>
              <b-col cols="12">
                <div class="text-right">
                  <h3 class="text-left float-left">
                    Lịch sử sao lưu dữ liệu
                  </h3>
                  <b-button
                    class="float-right ml-1"
                    variant="primary"
                    @click="exportExcel()"
                  >
                    <span class="text-nowrap text-right">
                      <feather-icon icon="DownloadIcon" /> Xuất Excel
                    </span>
                  </b-button>

                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="danger"
                    size="md"
                    class="ml-1"
                    @click="onCreateMainData"
                  >
                    <span class="text-nowrap text-right">
                      <feather-icon icon="LifeBuoyIcon" /> Tạo sao lưu
                    </span>
                  </b-button>

                </div>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col cols="12">
                <vue-good-table
                  mode="remote"
                  class="my-table"
                  style-class="my-table vgt-table striped bordered"
                  :columns="columns"
                  :rows="mainData"
                  :pagination-options="paginationOptions"
                  :total-rows="totalRows"
                  line-numbers
                  row-style-class="vgt-row"
                  @on-page-change="onPageChange"
                  @on-sort-change="onSortChange"
                  @on-column-filter="onColumnFilter"
                  @on-per-page-change="onPerPageChange"
                >
                  <div
                    slot="emptystate"
                    style="text-align: center; font-weight: bold"
                  >
                    Không có bản ghi nào !
                  </div>
                  <template
                    slot="table-row"
                    slot-scope="props"
                  >
                    <span
                      v-if="props.column.field === 'status'"
                      :class="(props.row.status) ? 'text-success' : 'text-danger'"
                      v-html="getStatusName(props.row.status)"
                    />
                    <span v-else-if="props.column.field === 'recoverCode'">
                      {{ getRecoveryInformation(props.row) }}
                    </span>
                    <span
                      v-else-if="props.column.field === 'action'"
                      class="thead-group-action"
                    >
                      <b-button-group>
                        <b-button
                          :disabled="!!(props.row.status)"
                          variant="danger"
                          class="btn-icon"
                          size="sm"
                          title="Xóa"
                          @click="onDeleteMainData(props.row)"
                        >
                          <feather-icon icon="TrashIcon" />
                        </b-button>

                        <b-button
                          variant="primary"
                          class="btn-icon"
                          size="sm"
                          title="Sửa"
                          @click="onEditMainData(props.row)"
                        >
                          <feather-icon icon="EditIcon" />
                        </b-button>

                        <b-button
                          :disabled="(!props.row.status)"
                          variant="success"
                          class="btn-icon"
                          size="sm"
                          title="Khôi phục"
                          @click="showModal(props.row)"
                        >
                          <feather-icon icon="RotateCcwIcon" />
                        </b-button>
                        <b-button
                          variant="primary"
                          class="btn-icon"
                          size="sm"
                          title="Tải file"
                          @click="downloadFileAsZip(props.row)"
                        >
                          <feather-icon icon="DownloadCloudIcon" />
                        </b-button>
                      </b-button-group>
                    </span>
                    <!-- Column: Common -->
                    <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                  </template>

                  <!-- pagination -->
                  <template
                    slot="pagination-bottom"
                    slot-scope="props"
                  >
                    <div class="d-flex justify-content-between flex-wrap">
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị {{ filter.itemsPerPage * (filter.currentPage-1) +1 }}
                          đến {{ filter.itemsPerPage * (filter.currentPage) }} &nbsp; của {{ totalRows }} bản ghi
                        </span>
                      </div>
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị </span>
                        <b-form-select
                          v-model="filter.itemsPerPage"
                          :options="itemsPerPageOptions"
                          class="mx-1"
                          @input="(value) => props.perPageChanged({ currentPerPage: value })"
                        />
                        <span class="text-nowrap"> bản ghi/trang</span>
                      </div>
                      <div>
                        <b-pagination
                          :value="1"
                          :total-rows="totalRows"
                          :per-page="filter.itemsPerPage"
                          class="mt-1 mb-0"
                          @input="(value) => props.pageChanged({ currentPage: value })"
                        />
                      </div>
                    </div>
                  </template>
                </vue-good-table>
              </b-col>
            </b-row>
          </div>
        </b-overlay>
      </b-card>
    </b-container>
    <InputPassword @succeed="onCreateRecover" />
    <b-modal
      id="modal-edit-backup"
      ref="modal-edit-backup"
      ok-only
      centered
      size="sm"
      title="Cập nhật trạng thái sao lưu"
      :hide-footer="true"
      :no-close-on-backdrop="true"
    >
      <div>
        <validation-observer
          ref="saveFormRef"
          #default="{invalid}"
        >
          <b-form>
            <b-row>
              <b-col cols="12">
                <b-form-group label-for="status">
                  <template v-slot:label>
                    Trạng thái <span class="text-danger">*</span>
                  </template>
                  <validation-provider
                    #default="{ errors }"
                    name="Trạng thái"
                    rules="required"
                  >
                    <v-select
                      id="status"
                      v-model="currentMainData.status"
                      :options="optStatus"
                      :reduce="option => option.value"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <div class="float-left">
                  <b-button
                    type="submit"
                    variant="primary"
                    class="mr-1"
                    :disabled="invalid"
                    @click="handleSaveData"
                  >
                    Lưu
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BButtonGroup,
  BCard,
  BCol,
  BContainer,
  BForm,
  BFormGroup,
  BFormSelect,
  BOverlay,
  BPagination,
  BRow,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-good-table.scss'
import Ripple from 'vue-ripple-directive'
import InputPassword from '@/views/changePassword/InputPassword.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import { STATUSES } from '@/const/status'
import * as XLSX from 'xlsx'

export const Attr = Object.freeze({
  store: 'maintenanceBackup',
})
export default {
  name: 'MaintenanceBackup',
  directives: {
    Ripple,
  },
  components: {
    BFormGroup,
    BForm,
    BButton,
    BButtonGroup,
    BCard,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    VueGoodTable,
    InputPassword,
    vSelect,
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    dataSend: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isLoading: false,
      filter: {
        currentPage: 1,
        itemsPerPage: 10,
        sort: '',
      },
      currentMainData: undefined,
      extMainData: { },
      paginationOptions: {
        enabled: true,
      },
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
      currentData: {},
      optStatus: [],
    }
  },
  computed: {
    ...mapGetters({
      mainData: `${Attr.store}/dataLists`,
      totalRows: `${Attr.store}/totalRows`,
      statuses: `${Attr.store}/statuses`,
      resourceName: `${Attr.store}/resourceName`,
      url: `${Attr.store}/url`,
    }),
    statusFilterDropdownItems() {
      return this.statuses.map(item => ({ value: item.value, text: item.label }))
    },
    columns() {
      return [
        {
          label: 'Phiên bản',
          field: 'code',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Tìm theo mã phiên bản',
          },
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Người tạo',
          field: 'createdAccount',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'TT',
          title: 'Trạng thái',
          field: 'status',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Ghi chú',
          field: 'recoverCode',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: '',
          field: 'action',
          width: '80px',
          sortable: false,
          tdClass: 'text-center',
        },
      ]
    },
  },
  async created() {
    this.isLoading = true
    this.optStatus = STATUSES
    try {
      await this.getMainData(this.filter)
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    ...mapActions({
      getMainData: `${Attr.store}/getData`,
      deleteMainData: `${Attr.store}/deleteData`,
      createMainData: `${Attr.store}/createData`,
      createRecover: 'maintenanceRecovery/createData',
      downloadFile: 'maintenanceBackup/downloadFile',
      updateBackup: `${Attr.store}/updateData`,
    }),
    async handleSaveData(e) {
      e.preventDefault()
      this.isLoading = true
      try {
        const response = await this.updateBackup(this.currentMainData)
        if (response) {
          const { isSuccessful, message } = response
          if (isSuccessful) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
          }
        }
      } catch (ex) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${ex.code}] ${ex.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
        this.$bvModal.hide('modal-edit-backup')
      }
    },
    onEditMainData(mainData) {
      const {
        rowNum,
        originalIndex,
        vgt_id,
        ...rest
      } = mainData
      this.currentMainData = rest
      this.$bvModal.show('modal-edit-backup')
    },
    exportExcel() {
      let data = []
      if (this.mainData.length > 0) {
        data = this.mainData.map(element => ({
          code: element.code,
          nguoi_tao: element.createdAccount,
          trang_thai: element.status == '1' ? 'hoạt động' : 'không hoạt động',
          ghi_chu: '',
        }))
      }
      const ws = XLSX.utils.json_to_sheet(data)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, 'scores')
      XLSX.writeFile(wb, 'danhsachsaoluu.xlsx')
    },
    onCreateMainData() {
      const event = new Date()
      const options = {
        weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric',
      }

      const localTime = event.toLocaleDateString('vi-GB', options)
      this.$swal({
        title: `Bạn chắc chắn muốn thực hiện sao lưu vào thời gian này? <strong class="text-danger">${localTime}</strong>`,
        text: 'Bạn nên thực hiện sao lưu vào thời điểm hệ thống thấp tải!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<i class="fa-solid fa-check"></i> Tạo sao lưu',
        cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.isLoading = true
          try {
            const request = {
              name: 'Sao lưu thủ công.',
              fromSchedulerId: null,
              fromRecoveryId: null,
              useCloud: 1,
            }
            const response = await this.createMainData(request)
            if (response) {
              const { isSuccessful, message } = response
              if (isSuccessful) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
                try {
                  await this.getMainData(this.filter)
                } catch (e) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: `[${e.code}] ${e.message}`,
                      icon: 'XCircleIcon',
                      variant: 'danger',
                    },
                  })
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'XCircleIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          } catch (e) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `[${e.code}] ${e.message}`,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          } finally {
            this.isLoading = false
          }
        }
      })
    },
    onDeleteMainData(mainData) {
      this.$swal({
        title: `Bạn chắc chắn muốn xóa ${this.resourceName.fullName} này? <span class="text-danger">${mainData.name}</span>`,
        text: 'Bạn sẽ không thể khôi phục lại dữ liệu này!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
        cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.isLoading = true
          try {
            const response = await this.deleteMainData(mainData.id)
            if (response) {
              const { isSuccessful, message } = response
              if (isSuccessful) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
                try {
                  await this.getMainData(this.filter)
                } catch (e) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: `[${e.code}] ${e.message}`,
                      icon: 'XCircleIcon',
                      variant: 'danger',
                    },
                  })
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'XCircleIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          } catch (e) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `[${e.code}] ${e.message}`,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          } finally {
            this.isLoading = false
          }
        }
      })
    },

    showModal(mainData) {
      this.$bvModal.show('inputPasswordModal')
      this.currentData = mainData
    },

    onCreateRecover(password) {
      this.$bvModal.hide('inputPasswordModal')
      const mainData = this.currentData
      this.$swal({
        title: `Bạn chắc chắn muốn khôi phục liệu về phiên bản ${this.resourceName.fullName} này? <span class="text-danger">${mainData.code}</span>`,
        text: 'Dữ liệu hiện tại sẽ được sao lưu lại!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
        cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.isLoading = true
          try {
            const response = await this.createRecover({ backupId: mainData.id, password })
            if (response) {
              const { isSuccessful, message } = response
              if (isSuccessful) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
                try {
                  this.$emit('success', null)
                  await this.getMainData(this.filter)
                } catch (e) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: `[${e.code}] ${e.message}`,
                      icon: 'XCircleIcon',
                      variant: 'danger',
                    },
                  })
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'XCircleIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          } catch (e) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `[${e.code}] ${e.message}`,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          } finally {
            this.isLoading = false
          }
        }
      })
    },
    updateParams(newProps) {
      this.filter = { ...this.filter, ...newProps }
    },
    async onPageChange(param) {
      this.updateParams({ currentPage: param.currentPage })
      await this.getMainDataFromStore()
    },
    async getMainDataFromStore() {
      this.isLoading = true
      try {
        await this.getMainData(this.filter)
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async onPerPageChange(param) {
      this.updateParams({ currentPage: 1, itemsPerPage: param.currentPerPage })
      await this.getMainDataFromStore()
    },
    async onSortChange(param) {
      if (param.length > 0) {
        const { field, type } = param[0]
        const selectedColumn = this.columns.findIndex(e => e.field === field)

        this.updateParams({ sort: `${selectedColumn}_${type}` })
      }
      await this.getMainDataFromStore()
    },
    async onColumnFilter(param) {
      const { columnFilters } = param
      if (Object.hasOwn(columnFilters, 'name')) this.updateParams({ name: columnFilters.name })
      if (Object.hasOwn(columnFilters, 'code')) this.updateParams({ code: columnFilters.code })
      if (Object.hasOwn(columnFilters, 'status')) this.updateParams({ status: columnFilters.status })
      await this.getMainDataFromStore()
    },
    getStatusName(id) {
      return this.statuses.find(status => status.value === id).label
    },
    getRecoveryInformation(data) {
      return data.recoverCode ? `Đã khôi phục về phiên bản này lúc ${data.recoverTime} bởi ${data.recoverAccount}, mã phiên bản khôi phục: ${data.recoverCode}` : ''
    },
    async onSucceed() {
      await this.getMainDataFromStore()
    },
    async downloadFileAsZip(dataRow) {
      this.isLoading = true
      try {
        await this.downloadFile({ id: dataRow.id })
        if (this.url) {
          window.open(this.url, '_blank')
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>
